// Environment variables are not available at build time.
// This file is used to define the application locations for each environment.

const locations = {
  PLATFORM_HOME: {
    DEV: 'http://dev.ready4reading.scholastic.com',
    QA: 'http://qa.ready4reading.scholastic.com',
    STAGE: 'http://stage.ready4reading.scholastic.com',
    PERF: 'http://perf.ready4reading.scholastic.com',
    PROD: 'http://ready4reading.scholastic.com'
  },
  STUDENT_HUB: {
    DEV: 'http://student-hub.dev.ready4reading.scholastic.com',
    QA: 'http://student-hub.qa.ready4reading.scholastic.com',
    STAGE: 'http://student-hub.stage.ready4reading.scholastic.com',
    PERF: 'http://student-hub.perf.ready4reading.scholastic.com',
    PROD: 'http://student-hub.ready4reading.scholastic.com'
  },
  TEACHER_HUB: {
    DEV: 'http://teacher-hub.dev.ready4reading.scholastic.com',
    QA: 'http://teacher-hub.qa.ready4reading.scholastic.com',
    STAGE: 'http://teacher-hub.stage.ready4reading.scholastic.com',
    PERF: 'http://teacher-hub.perf.ready4reading.scholastic.com',
    PROD: 'http://teacher-hub.ready4reading.scholastic.com'
  },
  TEACHER_LOGIN: {
    DEV: 'https://digital-dev.scholastic.com/sign-in/staff',
    QA: 'https://digital-qa.scholastic.com/sign-in/staff',
    STAGE: 'https://digital-stage.scholastic.com/sign-in/staff',
    PERF: 'https://digital-perf.scholastic.com/sign-in/staff',
    PROD: 'https://digital.scholastic.com/sign-in/staff'
  },
  STUDENT_LOGIN: {
    DEV: 'https://digital-dev.scholastic.com/sign-in',
    QA: 'https://digital-qa.scholastic.com/sign-in',
    STAGE: 'https://digital-stage.scholastic.com/sign-in',
    PERF: 'https://digital-perf.scholastic.com/sign-in',
    PROD: 'https://digital.scholastic.com/sign-in'
  },
  TEACHER_SDM_CLASSES: {
    DEV: 'https://digital-dev.scholastic.com/teacher/classes',
    QA: 'https://digital-qa.scholastic.com/teacher/classes',
    STAGE: 'https://digital-stage.scholastic.com/teacher/classes',
    PERF: 'https://digital-perf.scholastic.com/teacher/classes',
    PROD: 'https://digital.scholastic.com/teacher/classes'
  }
};

export default locations;
