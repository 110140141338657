export const ROLE = {
  EDUCATOR: 'educator',
  STUDENT: 'student'
} as const;

export const SDM_URL = {
  PROD: 'https://digital.scholastic.com'
} as const;

export const SDM_COOKIES = {
  sdmNavCTX: 'sdm_nav_ctx',
  r4r: 'R4R',
  jwt: 'R4R_JWT'
} as const;
