import React, { createContext, PropsWithChildren } from 'react';
import cookies from 'js-cookie';
import locations from './applicationLocations';
import getEnvironment from './util/getEnvironment';
import type { AppCode, ProductCode } from './types';
import { ModuleId } from './types';

export interface AuthenticationCookie {
  roles: string;
  entitlements: string;
}
export interface AuthenticationContext {
  roles: string;
  entitlements: ProductCode[];
  modules: ModuleId[];
}

interface AuthenticationProps {
  app: AppCode;
}

const COOKIE_NAME = 'R4R';

const AuthContext = createContext<AuthenticationContext>({
  roles: '',
  entitlements: [],
  modules: []
});

/**
 *
 * @component
 * @param AuthProvider
 * @param AuthProvider.app the current application
 *
 * @example
 *   <AuthProvider app="TEACHER_HUB">
 *     <App />
 *   </AuthProvider>
 *
 * @return a context provider that provides the user's authentication data or redirects to a different application
 */

function parseEntitlements(entitlementsString: string): ProductCode[] | [] {
  if (!entitlementsString || entitlementsString === '') {
    return [];
  }

  return entitlementsString.split(',') as ProductCode[];
}

function getR4RUrl(state: string, roles: string, environment: string, app: string): string {
  const STUDENT_HUB_URL = locations['STUDENT_HUB'][environment];
  const TEACHER_HUB_URL = locations['TEACHER_HUB'][environment];
  if (roles === 'student') {
    return STUDENT_HUB_URL;
  } else if (state) {
    return `${TEACHER_HUB_URL}${decodeURIComponent(state)}`;
  } else {
    return TEACHER_HUB_URL;
  }
}

function getLoginUrl(baseUrl: string): string {
  const path = window.location.pathname;
  if (!path || path === '/') {
    return `${baseUrl}?app=ready4reading`;
  } else {
    return `${baseUrl}?app=ready4reading&state=${encodeURIComponent(path)}`;
  }
}

const allModules = [ModuleId.R4R_WBTP, ModuleId.R4R_SRD, ModuleId.R4R_RTK, ModuleId.R4R_UA];

export function AuthProvider({ app, children }: PropsWithChildren<AuthenticationProps>) {
  const isLocalhost = window.location.hostname.includes('localhost');

  // disable authentication for localhost
  if (isLocalhost) {
    const roles = app === 'TEACHER_HUB' ? 'teacher' : 'student';
    return (
      <AuthContext.Provider
        value={{
          roles,
          entitlements: ['R4R_System'],
          modules: allModules
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }

  const cookie = cookies.get(COOKIE_NAME);
  const userData = JSON.parse(cookie || '{}');
  const { roles, entitlements = '', customState = '' } = userData;

  const productCodes = parseEntitlements(entitlements);
  const moduleIds = entitlements.includes('R4R_System')
    ? allModules
    : productCodes.map((code) => ModuleId[code]);

  const environment = getEnvironment();

  const STUDENT_LOGIN_URL = locations['STUDENT_LOGIN'][environment];
  const TEACHER_LOGIN_URL = locations['TEACHER_LOGIN'][environment];
  const r4rUrl = getR4RUrl(customState, roles, environment, app);

  switch (true) {
    case app === 'PLATFORM_HOME' && !cookie:
    case app === 'TEACHER_HUB' && !cookie:
      window.location.replace(getLoginUrl(TEACHER_LOGIN_URL));
      return null;
    case app === 'STUDENT_HUB' && !cookie:
      window.location.replace(getLoginUrl(STUDENT_LOGIN_URL));
      return null;
    case app === 'PLATFORM_HOME':
    case app === 'TEACHER_HUB' && roles === 'student':
      window.location.replace(r4rUrl);
      return null;
    case app === 'STUDENT_HUB':
    case app === 'TEACHER_HUB' && roles !== 'student':
      return (
        <AuthContext.Provider value={{ roles, entitlements: productCodes, modules: moduleIds }}>
          {children}
        </AuthContext.Provider>
      );
    default:
      return null;
  }
}

/**
 * React hook to return the contents of the authentication cookie
 *
 * @return {AuthenticationContext} the contents of the authentication cookie
 *
 * @example
 *   const {roles, entitlements } = useAuth()
 */
export function useAuth() {
  return React.useContext(AuthContext);
}
