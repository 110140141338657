import Cookies from 'js-cookie';
import * as t from 'io-ts';
import { SDM_URL, SDM_COOKIES } from './constants';
import { validateDataShape } from './hooks/use-fetch/use-fetch';

export interface Jwt {
  sub: string;
  iat: number;
  exp: number;
  iss: string;
}

const RoleShape = t.union([t.literal('student'), t.literal('educator')]);
export type Role = t.TypeOf<typeof RoleShape>;

const EnvShape = t.union([
  t.literal('dev'),
  t.literal('stage'),
  t.literal('qa'),
  t.literal('perf'),
  t.literal('prod')
]);
export type Env = t.TypeOf<typeof EnvShape>;

const SDMNavCtxShape = t.strict({
  admin: t.union([t.boolean, t.undefined]),
  appCode: t.string,
  appCodes: t.array(t.string),
  appId: t.string,
  env: EnvShape,
  extSessionEndpoint: t.string,
  extSessionId: t.string,
  firstName: t.string,
  iamUserId: t.union([t.string, t.undefined]),
  name: t.string,
  orgId: t.string,
  orgName: t.string,
  orgType: t.string,
  parentOrgId: t.union([t.string, t.undefined]),
  portalBaseUrl: t.string,
  role: RoleShape,
  staff: t.union([t.boolean, t.undefined]),
  user_id: t.string
});

export type SdmNavCtx = t.TypeOf<typeof SDMNavCtxShape>;

export const getSDMNavCTXCookie = (): SdmNavCtx => {
  const sdmNavContextString = Cookies.get(SDM_COOKIES['sdmNavCTX']);
  if (sdmNavContextString) {
    const sdmContext = validateDataShape(JSON.parse(sdmNavContextString), SDMNavCtxShape);
    sdmContext.name = sdmContext.name.split(' ')[0];
    return sdmContext;
  }
  throw Error('sdm_nav_ctx cookie not found');
};

export function logout(redirectWithAppCode: boolean) {
  let redirectUrl = getSDMNavCTXCookie().portalBaseUrl || SDM_URL.PROD;
  Cookies.remove(SDM_COOKIES.sdmNavCTX, { path: '' });
  Cookies.remove(SDM_COOKIES.r4r, { path: '' });
  Cookies.remove(SDM_COOKIES.jwt, { path: '' });
  sessionStorage.clear();
  if (redirectWithAppCode) {
    redirectUrl += '/#/launchApp?app=ready4reading';
  }
  window.location.href = redirectUrl;
}
