import type { Environment } from '../types'

/**
 * Returns the environment name based on the global window.location object.
 * Assumes that the URL structure matches the following pattern:
 *  http(s)://(<app-code>.)<environment>.ready4reading.scholastic.com
 *
 */

export default function getEnvironment(): Environment {
  const hostname = window.location.hostname
  switch (true) {
    case hostname === 'localhost':
      return 'LOCAL'
    case hostname.includes('dev'):
      return 'DEV'
    case hostname.includes('qa'):
      return 'QA'
    case hostname.includes('stage'):
      return 'STAGE'
    case hostname.includes('perf'):
      return 'PERF'
    default:
      return 'PROD'
  }
}
