export type AppCode = 'TEACHER_HUB' | 'STUDENT_HUB' | 'PLATFORM_HOME';

export type ProductCode = 'R4R_System' | 'R4R_WBTP' | 'R4R_SRD' | 'R4R_RTK';

export type Environment = 'LOCAL' | 'DEV' | 'QA' | 'STAGE' | 'PERF' | 'PROD';

export enum ModuleId {
  'R4R_WBTP' = 'r4r_module_001',
  'R4R_SRD' = 'r4r_module_002',
  'R4R_RTK' = 'r4r_module_003',
  'R4R_UA' = 'r4r_module_004'
}
